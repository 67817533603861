import { GlobalOptions } from '../types/api';

export type ConfigType = {
	aspnetcoreEnvironment?: string;
	buildInfo?: string;
	version?: string;
	globalOptions?: GlobalOptions;
};

export const CONFIG: ConfigType = Object.freeze(window.MQSA_CONFIG);
export const GLOBAL_OPTIONS: GlobalOptions = Object.freeze(CONFIG.globalOptions);

export const isTesting = CONFIG.aspnetcoreEnvironment === 'Testing';
export const isDevelopment = CONFIG.aspnetcoreEnvironment === 'Development';
export const isStaging = CONFIG.aspnetcoreEnvironment === 'Staging';
export const isProduction = CONFIG.aspnetcoreEnvironment === 'Production';
