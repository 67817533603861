import React from 'react';
import { css } from '@emotion/css';
import { grass, error, lightgrey } from '../../ui/Core/stylesheets/colors';
import { GLOBAL_OPTIONS } from '../constants/config';

function passwordCheckResult(password = '') {
	return {
		isMinLength: password.length >= GLOBAL_OPTIONS.password.minLength,
		hasLetters:
			!!password.match('[a-z]') &&
			(!GLOBAL_OPTIONS.password.requireUpperCaseChars || !!password.match('[A-Z]')),
		hasNumbers: !!password.match('\\d+'),
		hasSpecialChars: !!password.match('[!@#$%^&*]'),
	};
}

export function isPasswordSafe(password = '') {
	const { isMinLength, hasLetters, hasNumbers, hasSpecialChars } = passwordCheckResult(password);
	return (
		isMinLength &&
		hasLetters &&
		(!GLOBAL_OPTIONS.password.requireDigits || hasNumbers) &&
		(!GLOBAL_OPTIONS.password.requireSpecialChars || hasSpecialChars)
	);
}

type PasswordStrengthTypeProps = { isValid?: boolean; children: React.ReactNode };

function PasswordStrengthType({ isValid, children }: PasswordStrengthTypeProps) {
	return (
		<p
			className={css`
				color: ${isValid ? grass : error};
			`}
		>
			{children}
		</p>
	);
}

const rootClass = css`
	padding: 0.5rem;
	border-radius: 5px;
	background: ${lightgrey};
	margin-top: 0.5rem;
	text-align: left;
`;

type PasswordStrengthProps = { password: string };

export default function PasswordStrength({ password }: PasswordStrengthProps) {
	const status = React.useMemo(() => passwordCheckResult(password), [password]);

	return (
		<div className={rootClass}>
			<PasswordStrengthType isValid={status.isMinLength}>
				At least {GLOBAL_OPTIONS.password.minLength} characters long
			</PasswordStrengthType>
			<PasswordStrengthType isValid={status.hasLetters}>
				Contains {GLOBAL_OPTIONS.password.requireUpperCaseChars && 'uppercase and '}lowercase
				letters
			</PasswordStrengthType>
			{GLOBAL_OPTIONS.password.requireDigits && (
				<PasswordStrengthType isValid={status.hasNumbers}>
					Contains digits (0-9)
				</PasswordStrengthType>
			)}
			{GLOBAL_OPTIONS.password.requireSpecialChars && (
				<PasswordStrengthType isValid={status.hasSpecialChars}>
					Contains special characters (!, @, #, $, %, ^, &, *)
				</PasswordStrengthType>
			)}
		</div>
	);
}
