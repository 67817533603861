import { ReasonBase, UserMessage } from '../core/types/api';
import { LicenseStatus } from './LicenseStatus';

export type AccessToken = string | Promise<string>;

export type HubUserMessage = Partial<UserMessage> & {
	code: string;
	message: string;
	id: string;
	refresh?: () => void;
	creationDate?: string;
	viewed?: boolean;
};

export type HubSupportMessage = {
	id?: number;
	text?: string;
	userId?: string;
	readDate?: string;
};

export type HubSupportClientEvent = {
	message?: HubSupportMessage;
	readMessageIds?: number[];
	unreadMessageIds?: number[];
	noMessages?: boolean;
};

export type HubConnectionStatus = {
	error?: string;
	isConnected?: boolean;
};

export type HubNaUpdate = {
	projectId: string;
	naReasons: HubNaNewReasons[];
};

export type HubNaNewReasons = {
	itemId: string;
	naReasons: ReasonBase[];
};

export type HubState = {
	hubStatus: HubConnectionStatus;
	messages: HubUserMessage[];
	unreadMessageIds: string[];
	newItemStatus: [projectId: string, itemId: string, status: number];
	newItemWarnings: [projectId: string, itemId: string, rocWarnings: number];
	newProjectProgress: [string, string];
	licenseStatus: { current: LicenseStatus; first?: LicenseStatus; isChanged?: boolean };
	arrayImportStatuses: Record<string, Record<string, { isImporting?: boolean; status?: string }>>;
	supportMessages: HubSupportMessage[];
	unreadSupportMessageIds: number[];
	unreadSupportClientMessageIds: number[];
	newApprovalsCount: number;
	newNaReasons: HubNaUpdate;
	globalOptionsUpdate?: string;
};

export enum HubActionType {
	SET_STATUS = 'SET_STATUS',
	ADD_MESSAGE = 'ADD_MESSAGE',
	CLEAR_MESSAGES = 'CLEAR_MESSAGES',
	UPDATE_UNREAD_MESSAGE_IDS = 'UPDATE_UNREAD_MESSAGE_IDS',
	ADD_UNREAD_MESSAGE_ID = 'ADD_UNREAD_MESSAGE_ID',
	SET_UNREAD_MESSAGE_IDS = 'SET_UNREAD_MESSAGE_IDS',
	SET_NEW_ITEM_STATUS = 'SET_NEW_ITEM_STATUS',
	SET_NEW_ITEM_WARNINGS = 'SET_NEW_ITEM_WARNINGS',
	SET_PROJECT_STATUS = 'SET_PROJECT_STATUS',
	SET_LICENSE_STATUS = 'SET_LICENSE_STATUS',
	ARRAY_IMPORT_STATUS = 'ARRAY_IMPORT_STATUS',
	SET_APPROVAL_REQUESTS_COUNT = 'SET_APPROVAL_REQUESTS_COUNT',
	RECEIVE_SUPPORT_CLIENT_EVENT = 'RECEIVE_SUPPORT_CLIENT_EVENT',
	SET_NEW_NA_REASONS = 'SET_NEW_NA_REASONS',
	GLOBAL_OPTIONS_UPDATE = 'GLOBAL_OPTIONS_UPDATE',
	CLEAR_HUB = 'CLEAR_HUB',
}

export type HubAction =
	| {
			type: HubActionType.SET_STATUS;
			payload: HubConnectionStatus;
	  }
	| {
			type: HubActionType.CLEAR_HUB;
	  }
	| {
			type: HubActionType.UPDATE_UNREAD_MESSAGE_IDS;
			payload: { isRemove?: boolean; messageId: string };
	  }
	| {
			type: HubActionType.ADD_UNREAD_MESSAGE_ID;
			payload: string;
	  }
	| {
			type: HubActionType.SET_UNREAD_MESSAGE_IDS;
			payload: string[];
	  }
	| {
			type: HubActionType.ADD_MESSAGE;
			payload: HubUserMessage;
	  }
	| { type: HubActionType.CLEAR_MESSAGES }
	| {
			type: HubActionType.SET_NEW_ITEM_STATUS;
			payload: string;
	  }
	| {
			type: HubActionType.SET_NEW_ITEM_WARNINGS;
			payload: string;
	  }
	| {
			type: HubActionType.SET_PROJECT_STATUS;
			payload: string;
	  }
	| {
			type: HubActionType.SET_LICENSE_STATUS;
			payload: string;
	  }
	| {
			type: HubActionType.SET_APPROVAL_REQUESTS_COUNT;
			payload: string;
	  }
	| {
			type: HubActionType.ARRAY_IMPORT_STATUS;
			payload: string;
	  }
	| {
			type: HubActionType.SET_NEW_NA_REASONS;
			payload: string;
	  }
	| { type: HubActionType.RECEIVE_SUPPORT_CLIENT_EVENT; payload: HubSupportClientEvent }
	| {
			type: HubActionType.GLOBAL_OPTIONS_UPDATE;
			payload: string;
	  };
