import React from 'react';
import { ThemeProvider as ProviderV5 } from '@material-ui/core';
import { ThemeProvider as ProviderV6 } from '@mui/material';
import { themeV5, themeV6 } from './material';

type ThemeProviderWrapperProps = { children: React.ReactNode };

export default function ThemeProviderWrapper({ children }: ThemeProviderWrapperProps) {
	return (
		<ProviderV6 theme={themeV6}>
			<ProviderV5 theme={themeV5}>{children}</ProviderV5>
		</ProviderV6>
	);
}
