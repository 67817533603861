import React from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import DraftField, { DraftFieldProps } from './DraftField';
import { setInitialDraftEditorState } from './helpers';
import FormFieldWrapper from '../formaggio/components/FormFieldWrapper';

type DraftFieldSharedProps = Pick<
	DraftFieldProps,
	'className' | 'mentions' | 'name' | 'placeholder' | 'readOnly'
>;

type PureDraftFieldProps = DraftFieldSharedProps & {
	value: string;
	onChange: (newVal: string) => void;
};

/**
 * @param name
 * @param value Should be a raw JSON of draft state, see "setInitialDraftEditorState" helper for conversion
 * @param onChange
 * @param rest
 */
export function PureDraftField({ name, value, onChange, ...rest }: PureDraftFieldProps) {
	const [touched, setTouched] = React.useState(false);

	// Use separate editor state because reconversion for form state messes it up for draft.js
	const [editorState, setEditorState] = React.useState<EditorState>(
		setInitialDraftEditorState(value),
	);
	const handleChange = React.useCallback(
		(newState: EditorState) => {
			setTouched(true);

			if (typeof newState !== 'function')
				onChange(JSON.stringify(convertToRaw(newState.getCurrentContent())));

			setEditorState(newState);
		},
		[onChange],
	);

	// Reset both states on form reset
	React.useEffect(() => {
		if (!value && touched) {
			setEditorState(EditorState.createEmpty());
			setTouched(false);
		}
	}, [touched, value]);

	return (
		<DraftField setEditorState={handleChange} editorState={editorState} name={name} {...rest} />
	);
}

type FormDraftFieldProps = DraftFieldSharedProps;

export function FormDraftField({ name, ...rest }: FormDraftFieldProps) {
	return <FormFieldWrapper component={PureDraftField} name={name} compProps={{ ...rest, name }} />;
}
