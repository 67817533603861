import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { css } from '@emotion/css/macro';
import { Download, Edit, Undo } from '@mui/icons-material';
import { IconButton } from '../../core/components';
import { mainDark } from '../../ui/Core/stylesheets/colors';
import { useAllowedMethods, useReportAccess } from '../../auth/hooks';
import { ModelItemIdProps } from '../../rocTemplates/types';
import { ApprovalAuthor, MediaFile } from '../../core/types/api';
import FileInfo from './FileInfo';
import { hover } from '../../ui/Core/stylesheets/keyframes';
import { DownloadState } from '../../../shared/modules/files/constants';
import { extractAttachmentName } from '../helpers';
import { downloadFile } from '../../files/helpers';
import { useToggle } from '../../core/hooks';
import AttachmentEditForm from './AttachmentEditForm';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import { useAuthContext } from '../../auth/context';
import WorkflowAuthor from '../../workflow/components/WorkflowAuthor';
import ListUsagesButton from '../../rocTemplates/rocItems/attachments/ListUsagesButton';

const cardClass = css`
	align-items: center;
	display: flex;
	margin: 0 8px 8px 0;
	position: relative;
	min-width: 200px;
	padding-bottom: 0 !important;
`;

const cardContentClass = css`
	flex-grow: 1;
`;

type FolderTileProps = Partial<MediaFile> &
	Partial<ModelItemIdProps> & {
		children?: React.ReactNode;
		attachmentId?: string;
		additionalControls?: React.ReactNode;
		showApprovalAuthor?: boolean;
		approvalAuthors?: ApprovalAuthor[];
		viewableUsages?: boolean;
	};

export default function FolderTile({
	id,
	attachmentId,
	fileName,
	webUrl,
	modelId,
	itemId,
	children,
	additionalControls,
	createdBy,
	showApprovalAuthor,
	approvalAuthors,
	viewableUsages,
	...rest
}: FolderTileProps) {
	const {
		'Evidences/EvidenceDownload': canDownloadEvidence,
		'Evidences/UpdateAttachment': canEdit,
	} = useAllowedMethods();

	const { isAdmin, id: userId } = useAuthContext();
	const { projectId, users, contacts, refresh } = useReportContext();
	const { isQSA } = useReportAccess();

	const ids = React.useMemo(
		() => ({ project: modelId || projectId, attachment: id || attachmentId }),
		[attachmentId, id, modelId, projectId],
	);

	const [downloadState, setDownloadState] = React.useState<DownloadState>(0);

	const nameOfFile = React.useMemo(
		() => (fileName ? extractAttachmentName(fileName) : 'unknown file'),
		[fileName],
	);

	const download = React.useCallback(
		() =>
			downloadFile({
				setDownloadState,
				query: `Evidences/EvidenceDownload/${ids.project}/${ids.attachment}`,
				qs: { rocItemId: itemId },
				fileName: nameOfFile,
			}),
		[ids.attachment, ids.project, itemId, nameOfFile],
	);

	const renderApprovalAuthors = React.useMemo(
		() =>
			showApprovalAuthor &&
			approvalAuthors?.map((a, key) => (
				<WorkflowAuthor isApprovalType users={users} contacts={contacts} key={key} {...a} />
			)),
		[approvalAuthors, contacts, showApprovalAuthor, users],
	);

	const { isOn, toggle, toggleOff } = useToggle();

	const isDownloading = downloadState === DownloadState.Downloading;

	const fileInfo = <FileInfo fileName={fileName} webUrl={webUrl} {...rest} createdBy={createdBy} />;

	return (
		<Card className={cardClass}>
			{!!children && <CardContent className={cardContentClass}>{children}</CardContent>}
			<CardContent>
				{isOn ? (
					<AttachmentEditForm
						id={ids.attachment}
						fileName={fileName}
						modelId={ids.project}
						refetch={refresh}
						webUrl={webUrl}
						toggleFormOff={toggleOff}
						createdBy={createdBy}
						{...rest}
					/>
				) : (
					fileInfo
				)}
				{showApprovalAuthor && approvalAuthors && <div>{renderApprovalAuthors}</div>}
			</CardContent>
			<CardActions>
				{canDownloadEvidence && !webUrl && (
					<IconButton disabled={isDownloading} onClick={download} size="small">
						<Download
							htmlColor={mainDark}
							style={{
								animation: isDownloading ? `${hover} 1s infinite ease-in-out` : 'none',
								opacity: isDownloading ? 0.5 : 1,
							}}
						/>
					</IconButton>
				)}
				{canEdit && (isAdmin || isQSA || createdBy?.authorId === userId) && (
					<IconButton onClick={toggle} size="small">
						{isOn ? <Undo /> : <Edit />}
					</IconButton>
				)}
				{viewableUsages && ids.project && ids.attachment && (
					<ListUsagesButton id={ids.attachment} projectId={ids.project} fileInfo={fileInfo} />
				)}
				{additionalControls}
			</CardActions>
		</Card>
	);
}
