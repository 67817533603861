import React from 'react';
import { Box, Button } from '@mui/material';
import { PureSelectField, PureTextField } from '../formaggio';
import { useMultiFieldHandler } from '../core/helpers/stateActionHelpers';
import { FieldSet } from '../core/components';

export default function useAttachmentFilter(
	initialFilter: any = {},
	options: {
		sortBy?: { title: string; value: number }[];
		usageBy?: { title: string; value: number }[];
		withRequirement?: boolean;
		withAuthorName?: boolean;
	},
) {
	const initialState = {
		filter: '',
		error: '',
		...initialFilter,
	};
	const [filter, setFilter] = React.useState(initialState);
	const changeFilter = useMultiFieldHandler(setFilter);

	return {
		filter,
		filterComponent: (
			<FieldSet legend="Filter">
				<Box display="flex" flexWrap="wrap" alignItems="center">
					<PureTextField
						onChange={changeFilter}
						value={filter.filter}
						fullWidth
						name="filter"
						label="Name"
						errorMessage={filter.error}
						helperText="or URL or description"
					/>
					{options.withRequirement && (
						<PureTextField
							onChange={changeFilter}
							value={filter.requirement}
							label="Requirement"
							name="requirement"
							helperText="R1-R12, A1-A2"
							noEmpty
						/>
					)}
					{options.withAuthorName && (
						<PureTextField
							onChange={changeFilter}
							value={filter.name}
							label="Uploaded by"
							name="name"
							noEmpty
						/>
					)}
					{options.sortBy && (
						<PureSelectField
							onChange={changeFilter}
							value={filter.sortBy}
							label="Sort by"
							name="sortBy"
							options={options.sortBy}
							noEmpty
						/>
					)}
					{options.usageBy && (
						<PureSelectField
							onChange={changeFilter}
							value={filter.usageBy}
							label="Usage"
							name="usageBy"
							options={options.usageBy}
							noEmpty
						/>
					)}
					{options.usageBy && (
						<Button onClick={() => setFilter(initialState)} variant="outlined">
							Reset
						</Button>
					)}
				</Box>
			</FieldSet>
		),
	};
}
