import React from 'react';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { css, cx } from '@emotion/css/macro';
import { PropTypes } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { IconButton } from '../../core/components';
import MaterialForm, { MaterialFormProps } from './MaterialForm';
import { FormaggioProps } from '../../formaggio/components/wrappers/BasicForm';
import { useBackdropClickHandler } from '../hooks';
import { darkness } from '../../../shared/stylesheets/constants';

const toolbarClass = css`
	display: flex;
	justify-content: space-between;
`;

const rootClass = css`
	max-width: 1200px;
	min-width: 400px;
`;

const containerClass = css`
	padding: 8px;
`;

type MaterialFlyOutProps = {
	open: any;
	onClose: any;
	children: React.ReactNode;
	title: string;
	className?: string;
	style?: React.CSSProperties;
	color?: PropTypes.Color;
	formProps?: Omit<FormaggioProps, 'children'> & MaterialFormProps;
	titleChildren?: React.ReactNode;
} & DrawerProps;

export default function MaterialFlyOut({
	open,
	onClose,
	children,
	title,
	className,
	style,
	color = 'secondary',
	PaperProps,
	formProps,
	titleChildren,
	...rest
}: MaterialFlyOutProps) {
	const closeHandler = useBackdropClickHandler(onClose);

	return (
		<Drawer
			anchor="right"
			open={open}
			{...rest}
			onClose={closeHandler}
			PaperProps={{ ...PaperProps, className: rootClass }}
		>
			<AppBar color={color} position="sticky">
				<Toolbar className={toolbarClass}>
					<Typography variant="h6">{title}</Typography>
					{titleChildren && <Box color={darkness}>{titleChildren}</Box>}
					<Tooltip title="or [Esc] key to close this dialog">
						<IconButton
							style={{ marginLeft: '1rem' }}
							color="primary"
							size="small"
							onClick={onClose}
						>
							<Close htmlColor="white" />
						</IconButton>
					</Tooltip>
				</Toolbar>
			</AppBar>
			<div style={style} className={cx(containerClass, className)}>
				{open && <MaterialForm {...formProps}>{children}</MaterialForm>}
			</div>
		</Drawer>
	);
}
