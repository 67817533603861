import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useFormikContext } from 'formik';
import { css } from '@emotion/css/macro';
import { compareAsc, compareDesc } from 'date-fns';
import { MediaFile } from '../../core/types/api';
import { phrases } from '../../core/constants/phrases';
import FileInfo from './FileInfo';
import { EvidenceAttachments } from './EvidenceCreate';
import { extractAttachmentName } from '../helpers';
import { requirementNumberToString } from '../../rocTemplates/helpers';
import { extractMimeTypeFromFileName, isValidMimeType } from '../../files/helpers';
import useAttachmentFilter from '../../attachments/AttachmentFilter';
import { searchTextFilter } from '../../../shared/helpers';
import { AcceptFileType } from '../../files/constants';
import { AttachmentWithEdit } from './ProjectAttachmentsList';

type ExistingFileChoiceProps = {
	isSelected?: boolean;
	setFileChoice: any;
	setFileDescription: any;
} & MediaFile;

function ExistingFileChoice({
	isSelected,
	id,
	fileName,
	webUrl,
	description,
	setFileChoice,
	setFileDescription,
	actualDateTime,
	...rest
}: ExistingFileChoiceProps) {
	return (
		<div>
			<FormControlLabel
				control={<Checkbox name={id} checked={isSelected || false} onChange={setFileChoice} />}
				style={{ margin: '0.25rem 0' }}
				label={
					<FileInfo
						fileName={fileName}
						webUrl={webUrl}
						description={description}
						actualDateTime={actualDateTime}
						{...rest}
					/>
				}
			/>
			{isSelected && (
				<TextField
					label={phrases.evidenceLocation}
					name={id}
					onChange={setFileDescription}
					fullWidth
					multiline
					variant="outlined"
					style={{ margin: '0.5rem 0' }}
				/>
			)}
		</div>
	);
}

enum SortBy {
	DateDescending = 0,
	DateAscending = 1,
	NameAscending = 2,
	NameDescending = 3,
}

const sortByOptions = [
	{ title: 'Date (ascending)', value: SortBy.DateAscending },
	{ title: 'Date (descending)', value: SortBy.DateDescending },
	{ title: 'Name (A-Z)', value: SortBy.NameAscending },
	{ title: 'Name (Z-A)', value: SortBy.NameDescending },
];

enum Usage {
	Any = 0,
	Used = 1,
	Unused = 2,
}

const usageOptions = [
	{ title: 'Any', value: Usage.Any },
	{ title: 'Used', value: Usage.Used },
	{ title: 'Unused', value: Usage.Unused },
];

const rootClass = css`
	display: flex;
	flex-direction: column;
`;

const listClass = css`
	margin-bottom: 16px;
	max-height: 300px;
	overflow-y: auto;
	padding: 16px 0;
`;

function getNameFromAttachment({
	fileName,
	description,
	webUrl,
}: MediaFile & { isAscending?: boolean }) {
	if (webUrl) return description || webUrl;

	return extractAttachmentName(fileName);
}

function getSorting(media1: MediaFile, media2: MediaFile, opposite?: boolean) {
	return getNameFromAttachment(opposite ? media1 : media2);
}

function cleanUpMediaFileName({ description = '', fileName = '', webUrl = '' }: MediaFile) {
	const cleanFileName = fileName ? extractAttachmentName(fileName) : '';

	return String(description + webUrl + cleanFileName.replace('_', ' '));
}

function renderFilterList(
	list: MediaFile[],
	filter: any,
	setFileChoice: any,
	setFileDescription: any,
	files: any,
	accept?: AcceptFileType,
	noChoice?: boolean,
	setUsedPlacesList?: any,
	refetch?: any,
) {
	return list
		.filter(({ contentType, fileName }) =>
			accept
				? accept.some((a) =>
						isValidMimeType(contentType || extractMimeTypeFromFileName(fileName), a),
					)
				: true,
		)
		.filter((media) =>
			filter.filter ? searchTextFilter(cleanUpMediaFileName(media), filter.filter) : true,
		)
		.filter(({ requirementNumber }) =>
			filter.requirement
				? searchTextFilter(requirementNumberToString(requirementNumber), filter.requirement, true)
				: true,
		)
		.filter(({ createdBy }) =>
			filter.name && createdBy ? searchTextFilter(createdBy.authorName, filter.name) : true,
		)
		.filter(({ referenceItems = [] }) => {
			if (!filter.usageBy) return true;

			return filter.usageBy === Usage.Used
				? referenceItems.length > 0
				: referenceItems.length === 0;
		})
		.sort(
			({ uploadDateTime: date1 = '', ...media1 }, { uploadDateTime: date2 = '', ...media2 }) => {
				switch (filter.sortBy) {
					case SortBy.DateAscending:
					case SortBy.DateDescending: {
						const compare = filter.sortBy === SortBy.DateAscending ? compareAsc : compareDesc;
						return compare(new Date(date1), new Date(date2));
					}
					case SortBy.NameAscending:
					case SortBy.NameDescending: {
						const isAscending = filter.sortBy === SortBy.NameAscending;

						return String(getSorting(media1, media2, isAscending)).localeCompare(
							getSorting(media1, media2, !isAscending),
						);
					}
					default:
						return 0;
				}
			},
		)
		.map(({ id = '', ...attachment }: MediaFile, idx) =>
			noChoice ? (
				<AttachmentWithEdit
					key={id}
					id={id}
					{...attachment}
					setUsedPlacesList={setUsedPlacesList}
					refetch={refetch}
				/>
			) : (
				<ExistingFileChoice
					key={idx}
					isSelected={files && files.indexOf(id) >= 0}
					setFileChoice={setFileChoice}
					setFileDescription={setFileDescription}
					id={id}
					{...attachment}
				/>
			),
		);
}

type FileListProps = {
	list?: MediaFile[];
	accept?: AcceptFileType;
	noChoice?: boolean;
	setUsedPlacesList?: any;
	refetch?: any;
};

export default function FileList({
	list,
	accept,
	noChoice,
	setUsedPlacesList,
	refetch,
}: FileListProps) {
	const { filter, filterComponent } = useAttachmentFilter(
		{ requirement: '', sortBy: SortBy.DateDescending, name: '', usageBy: Usage.Any },
		{
			sortBy: sortByOptions,
			usageBy: noChoice ? usageOptions : undefined,
			withRequirement: true,
			withAuthorName: noChoice,
		},
	);

	const { setFieldValue } = useFormikContext() || {};

	const [attachments, setAttachments] = React.useState<EvidenceAttachments>({});

	React.useEffect(() => {
		if (setFieldValue) setFieldValue('existingAttachments', attachments);
	}, [attachments, setFieldValue]);

	const setFileChoice = React.useCallback(
		(e) => {
			const { checked, name } = e.target;
			setAttachments((prevData) => {
				const newData: any = { ...prevData };
				if (!checked) {
					delete newData[name];
					return newData;
				}
				return { ...newData, [name]: '' };
			});
		},
		[setAttachments],
	);

	const setFileDescription = React.useCallback((e) => {
		const { value, name } = e.target;
		setAttachments((prevData) => ({ ...prevData, [name]: value }));
	}, []);

	const files = Object.keys(attachments);

	const renderList = React.useMemo(
		() =>
			renderFilterList(
				list || [],
				filter,
				setFileChoice,
				setFileDescription,
				files,
				accept,
				noChoice,
				setUsedPlacesList,
				refetch,
			),
		[
			list,
			filter,
			setFileChoice,
			setFileDescription,
			files,
			accept,
			noChoice,
			setUsedPlacesList,
			refetch,
		],
	);

	return (
		<div className={rootClass}>
			{filterComponent}
			<div className={noChoice ? undefined : listClass}>{renderList}</div>
		</div>
	);
}
