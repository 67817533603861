import { connect } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import { get } from 'lodash';
import { FormaggioInputProps } from '../types';
import { ErrorMessage } from '../../core/components';

type CheckboxFieldProps = CheckboxProps & FormaggioInputProps;

export function PureCheckboxField({
	label,
	helperText,
	errorMessage,
	className,
	...rest
}: CheckboxFieldProps) {
	return (
		<FormControl className={className}>
			<FormControlLabel control={<Checkbox {...rest} />} label={label} />
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			<ErrorMessage message={errorMessage} />
		</FormControl>
	);
}

function CheckboxFieldConnected({ formik, name = '', ...rest }: CheckboxFieldProps) {
	return (
		<PureCheckboxField
			onChange={formik.handleChange}
			storage={formik.values}
			onBlur={formik.handleBlur}
			errorMessage={formik.errors[name]}
			name={name}
			checked={get(formik.values, name, false) || false}
			{...rest}
		/>
	);
}

export default connect(CheckboxFieldConnected);
