import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { Box } from '@mui/material';
import { darkness, mainDark, middlegrey } from '../../ui/Core/stylesheets/colors';
import { getContrastText } from '../../ui/Core/helpers';

const simpleButtonClass = css`
	border-radius: 5px;
	background: white;
	padding: 2px 8px;
	color: ${darkness};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	border: 0;
	justify-content: space-between;
	align-items: center;
	font: inherit;
	font-size: 13px;
	line-height: 14px;

	&:hover {
		opacity: 0.75;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

const noIconClass = css`
	padding: 8px;
`;

const linkButtonClass = css`
	border: 1px solid ${middlegrey};
`;

const mainDarkContrast = getContrastText(mainDark);
const filledClass = css`
	background: ${mainDark};
	color: ${mainDarkContrast};
`;

type SimplifiedButtonProps = any;

const SimplifiedButton = React.forwardRef(
	({ children, startIcon, className, isLink, isFilled, ...rest }: SimplifiedButtonProps, ref) => (
		<button
			type="button"
			className={cx(
				simpleButtonClass,
				!startIcon && noIconClass,
				!isLink && linkButtonClass,
				isFilled && filledClass,
				className,
			)}
			{...rest}
			ref={ref}
		>
			{startIcon && (
				<Box marginRight="5px" color={isFilled ? mainDarkContrast : mainDark}>
					{startIcon}
				</Box>
			)}
			{children}
		</button>
	),
);

export default React.memo(SimplifiedButton);
