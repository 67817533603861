import React from 'react';
import { css, cx, keyframes } from '@emotion/css/macro';
import Button from '@material-ui/core/Button';
import { mobileMedia } from '../stylesheets/screenSizes';
import { Card, CardHeader } from './Card';
import Footer from './Footer';
import Header from './Header';
import { logoBackground, mainLight } from '../../ui/Core/stylesheets/colors';
import { getContrastText } from '../../ui/Core/helpers';
import { useHistoryPush } from '../hooks';

const EQUAL_SIDES_WIDTH = '260px';

const gatesClass = css`
	display: flex;
	padding: 0 2.5rem;
	flex-direction: column;
`;

const gatesBoxClass = css`
	padding: 0;
	margin: 2.5rem auto 0 auto;
	max-width: 600px;
	flex-direction: row;
	min-height: 400px;
`;

const leftSideClass = css`
	min-width: ${EQUAL_SIDES_WIDTH};
	max-width: ${EQUAL_SIDES_WIDTH};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

//edge calc no comprende
const scanning = keyframes`
	50% { top: 95%; }
	75% { top: 45%; }
	100% { top: 0; }
`;

const rightSideClass = css`
	background: ${logoBackground};
	display: flex;
	min-width: ${EQUAL_SIDES_WIDTH};
	position: relative;
	${mobileMedia} {
		display: none;
	}

	&::after {
		content: '';
		display: none;
		position: absolute;
		width: 100%;
		height: 32px;
		top: 0;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), ${mainLight}, rgba(0, 0, 0, 0));
		animation: ${scanning} 2s linear infinite;
	}
`;

const rightSideShowLoaderClass = css`
	&::after {
		display: block;
	}
`;

const middleClass = css`
	background: ${mainLight};
	color: ${getContrastText(mainLight)};
	width: 100%;
`;

const footerClass = css`
	padding: 0;
	background: ${mainLight};
	& > * {
		color: ${getContrastText(mainLight)};
	}
	width: 100%;
`;

const mainClass = css`
	padding: 1.5rem 2rem;
	margin: auto;
`;

const homeClass = css`
	position: relative;
	z-index: 1;
	margin: auto;
`;

const fullWidthClass = css`
	max-width: initial;
`;

const backToLoginClass = css`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.5rem;
`;

type GatesProps = {
	children: React.ReactNode;
	className?: string;
	boxClassName?: string;
	title?: string | React.ReactNode;
	showLoader?: boolean;
	fullWidth?: boolean;
	hideRightSide?: boolean;
	showBackToLogin?: boolean;
};

export default function Gates({
	children,
	className,
	boxClassName,
	title,
	showLoader,
	fullWidth,
	hideRightSide,
	showBackToLogin,
}: GatesProps) {
	const backToLogin = useHistoryPush('/');

	return (
		<section className={cx(gatesClass, className)}>
			<Card className={cx(gatesBoxClass, fullWidth && fullWidthClass)}>
				<div className={cx(leftSideClass, fullWidth && fullWidthClass)}>
					{title && <CardHeader className={middleClass}>{title}</CardHeader>}
					<div className={cx(mainClass, boxClassName)}>{children}</div>
					{showBackToLogin && (
						<div className={backToLoginClass}>
							<Button onClick={backToLogin} size="small" variant="outlined">
								Back to login
							</Button>
						</div>
					)}
					<CardHeader className={footerClass}>{Footer}</CardHeader>
				</div>
				{!hideRightSide && (
					<div className={cx(rightSideClass, showLoader && rightSideShowLoaderClass)}>
						<a className={homeClass} href="/">
							{Header}
						</a>
					</div>
				)}
			</Card>
		</section>
	);
}
