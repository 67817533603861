import React, { lazy } from 'react';

export const Portal: React.LazyExoticComponent<any> = lazy(() => import('../pages/Portal'));

export const Main: React.LazyExoticComponent<any> = lazy(() => import('../pages/Main'));

export const Inactive: React.LazyExoticComponent<any> = lazy(() => import('../pages/Inactive'));

export const ContactAccess: React.LazyExoticComponent<any> = lazy(() => import('../pages/Contact'));

export const Profile: React.LazyExoticComponent<any> = lazy(() => import('../pages/profile'));

export const Company: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/company/components/UserCompanies'),
);

export const Contacts: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/contact/components/ContactsListPage'),
);

export const Interviews: React.LazyExoticComponent<any> = lazy(
	() => import('../../Interviews/Interviews'),
);

export const Project: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/project/components'),
);

export const Projects: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/project/components/admin'),
);

export const Clients: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/client'),
);

export const Templates: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/rocTemplates'),
);

export const Users: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/users/components/UsersList'),
);

export const AuditLog: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/auditlog/components/Main'),
);

export const Evidence: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/evidence/components/ProjectEvidence'),
);

export const Invitation: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/project/invitation'),
);

export const Responsibilities: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/project/components/ProjectResponsibilityAreas'),
);

export const Respond: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/request/Respond'),
);

export const Reports: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/report'),
);

export const RocQa: React.LazyExoticComponent<any> = lazy(() => import('../../../modules/rocQa'));

//export const Messages: React.LazyExoticComponent<any> = lazy(() => import('../pages/messages'));

export const GlobalAdmin: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/admin'),
);

export const ProjectDashboard: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/dashboard/project'),
);

export const Invitations: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/tokens/components/TokenList'),
);

export const CustomSaqEdit: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/customSaq/components/CustomSaqEditor'),
);

export const MPA: React.LazyExoticComponent<any> = lazy(
	() => import('../../../modules/mpa/components/MPA'),
);
