import React from 'react';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Button } from '../../core/components';
import { useReportAccess } from '../../auth/hooks';
import { useItemContext } from '../../rocTemplates/context/ItemContext';
import { ItemStatus } from '../../project/enums';
import { useAPI, useToggle } from '../../core/hooks';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import { WorkflowRole } from '../../core/types/api';
import { useItemStatus } from '../../rocTemplates/hooks';
import SimplifiedButton from '../../rocTemplates/simplifiedControls/SimplifiedButton';
import { WorkflowDraftField } from './WorkflowDraftField';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';
import { GLOBAL_OPTIONS } from '../../core/constants/config';

function getApprovedDecision(workflowRole: WorkflowRole, isQAEnabled: boolean): ItemStatus | null {
	switch (workflowRole) {
		case WorkflowRole.PeerReviewer:
			return isQAEnabled ? ItemStatus.ReadyForQA : ItemStatus.Approved;
		case WorkflowRole.Qsa:
			return ItemStatus.ReadyForPeerReview;
		case WorkflowRole.QA:
			return ItemStatus.Approved;
		case WorkflowRole.AssociateQsa:
			return ItemStatus.ReadyForMentorReview;
		default:
			return null;
	}
}

export default function WorkflowQuickApprove() {
	const { workflowRole } = useReportAccess();
	const { projectId } = useReportContext();
	const { itemId } = useItemContext();
	const status = useItemStatus();
	const { initialFetch } = useAPI({
		props: { query: 'Workflow/Create', method: 'POST', useFormData: true },
	});
	const roleDecision = React.useMemo(() => {
		const by = {
			peerReviewer:
				workflowRole === WorkflowRole.PeerReviewer &&
				(status === ItemStatus.ReadyForPeerReview ||
					(!GLOBAL_OPTIONS.workflowQAEnabled && status === ItemStatus.ReadyForQA)),
			qa: workflowRole === WorkflowRole.QA && status === ItemStatus.ReadyForQA,
		};

		let approveLabel = '';
		let declineLabel = '';
		let declineStatus: ItemStatus = ItemStatus.ChangesRequested;

		if (
			workflowRole === WorkflowRole.Qsa &&
			(status === ItemStatus.WaitingForQsa || status === ItemStatus.ReadyForMentorReview)
		) {
			approveLabel = 'Move to peer review';

			if (status === ItemStatus.ReadyForMentorReview) {
				declineLabel = 'Wait for trainee';
				declineStatus = ItemStatus.WaitingForTrainee;
			}
		}
		if (workflowRole === WorkflowRole.AssociateQsa && status === ItemStatus.WaitingForTrainee)
			approveLabel = 'Ready for mentor review';
		if (by.peerReviewer || by.qa) declineLabel = 'Request changes';
		if (by.peerReviewer) approveLabel = GLOBAL_OPTIONS.workflowQAEnabled ? 'Move to QA' : 'Approve';
		if (by.qa) approveLabel = 'Approve';

		return {
			by,
			approveLabel,
			declineLabel,
			approveStatus: getApprovedDecision(workflowRole, GLOBAL_OPTIONS.workflowQAEnabled),
			declineStatus,
		};
	}, [status, workflowRole]);

	const decide = React.useCallback(
		(decision: boolean, message?: string) => {
			initialFetch({
				params: {
					projectId,
					itemId,
					status: decision ? roleDecision.approveStatus : roleDecision.declineStatus,
					message,
					visibleToCustomers: false,
				},
			});
		},
		[initialFetch, projectId, itemId, roleDecision.approveStatus, roleDecision.declineStatus],
	);

	const { isOn, toggleOn, toggleOff } = useToggle();
	const [reason, setReason] = React.useState('');
	const decline = React.useCallback(() => {
		decide(false, reason);
		toggleOff();
	}, [decide, reason, toggleOff]);

	const handleApprove = React.useCallback(() => decide(true), [decide]);

	return !projectId || roleDecision.approveStatus === null ? null : (
		<>
			{roleDecision.approveLabel && (
				<SimplifiedButton startIcon={<ThumbUp />} onClick={handleApprove}>
					{roleDecision.approveLabel}
				</SimplifiedButton>
			)}
			{roleDecision.declineLabel && (
				<SimplifiedButton startIcon={<ThumbDown />} onClick={toggleOn}>
					{roleDecision.declineLabel}
				</SimplifiedButton>
			)}
			{isOn && (
				<MaterialFlyOut
					open={isOn}
					onClose={toggleOff}
					title="Enter reason why this item was declined..."
				>
					<WorkflowDraftField
						value={reason}
						onChange={setReason}
						placeholder="Reason..."
						projectId={projectId}
					/>
					<Box mt="8px">
						<Button color="primary" onClick={decline}>
							Submit
						</Button>
						<Button color="secondary" onClick={toggleOff}>
							Cancel
						</Button>
					</Box>
				</MaterialFlyOut>
			)}
		</>
	);
}
