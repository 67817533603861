import React, { Dispatch, Reducer } from 'react';
import { useDispatch } from 'react-redux';
import { HubAction, HubActionType, HubState } from './types';
import { hub, HubTriggers } from './constants';
import { CONFIG, isDevelopment } from '../core/constants/config';
import { EmptyLicenseStatus, stringToLicenseStatus } from './LicenseStatus';
import { popUpShow } from '../popup/reducers';
import { error, success as successColor } from '../ui/Core/stylesheets/colors';
import { FeatureFlag } from '../license/featureFlags';

type HubContextType = [HubState, Dispatch<HubAction>];

const initialState: HubState = {
	hubStatus: { isConnected: true },
	messages: [],
	unreadMessageIds: [],
	newItemStatus: ['', '', 0],
	newItemWarnings: ['', '', 0],
	newProjectProgress: ['', ''],
	licenseStatus: {
		current: EmptyLicenseStatus,
	},
	arrayImportStatuses: {},
	supportMessages: [],
	unreadSupportMessageIds: [],
	unreadSupportClientMessageIds: [],
	newApprovalsCount: null,
	newNaReasons: { projectId: null, naReasons: [] },
};

const HubContext = React.createContext<HubContextType>([initialState, () => undefined]);

function reducer(state: HubState, action: HubAction, reduxDispatch: Dispatch<any>): HubState {
	switch (action.type) {
		case HubActionType.SET_STATUS: {
			return { ...state, hubStatus: action.payload };
		}
		case HubActionType.CLEAR_HUB: {
			return initialState;
		}
		case HubActionType.UPDATE_UNREAD_MESSAGE_IDS: {
			const newIds = [...state.unreadMessageIds];
			const { messageId } = action.payload;
			const index = newIds.indexOf(messageId);
			if (action.payload.isRemove) {
				if (index >= 0) newIds.splice(index, 1);
			} else if (index < 0) newIds.push(messageId);
			return { ...state, unreadMessageIds: newIds };
		}
		case HubActionType.ADD_UNREAD_MESSAGE_ID:
			return {
				...state,
				unreadMessageIds: [...state.unreadMessageIds, action.payload],
			};
		case HubActionType.SET_UNREAD_MESSAGE_IDS:
			return { ...state, unreadMessageIds: action.payload };
		case HubActionType.ADD_MESSAGE:
			return {
				...state,
				unreadMessageIds: [...state.unreadMessageIds, action.payload.id],
				messages: [action.payload, ...state.messages],
			};
		case HubActionType.CLEAR_MESSAGES: {
			return { ...state, messages: [] };
		}
		case HubActionType.SET_NEW_ITEM_STATUS: {
			const splitStatus: any = action.payload.split('_');
			splitStatus[2] = Number(splitStatus[2]);

			return {
				...state,
				newItemStatus: splitStatus,
			};
		}
		case HubActionType.SET_NEW_ITEM_WARNINGS: {
			const splitWarningMsg: any = action.payload.split('_');
			splitWarningMsg[2] = Number(splitWarningMsg[2]);

			return {
				...state,
				newItemWarnings: splitWarningMsg,
			};
		}
		case HubActionType.SET_PROJECT_STATUS: {
			const splitProgress: any = action.payload.split('_');

			return {
				...state,
				newProjectProgress: splitProgress,
			};
		}
		case HubActionType.SET_LICENSE_STATUS: {
			const newStatus = stringToLicenseStatus(action.payload);
			const firstStatus = state.licenseStatus.first;

			window.MQSA_FEATURES = Object.freeze(newStatus.features || []) as FeatureFlag[];

			return {
				...state,
				licenseStatus: {
					first: firstStatus || newStatus,
					current: newStatus,
					isChanged:
						!!firstStatus &&
						(JSON.stringify(firstStatus) !== JSON.stringify(newStatus) ||
							CONFIG.buildInfo !== newStatus.build),
				},
			};
		}
		case HubActionType.ARRAY_IMPORT_STATUS: {
			const parsedStatus: {
				arrayId: string;
				projectId: string;
				status?: string;
				isImporting?: boolean;
			} = JSON.parse(action.payload) || {};

			if (!parsedStatus) return state;

			if (!parsedStatus.isImporting && parsedStatus.status === 'success') {
				reduxDispatch(
					popUpShow({
						state: { text: 'Table has been successfully imported', color: successColor },
						autoClose: true,
					}) as any,
				);
			} else if (parsedStatus.status) {
				reduxDispatch(
					popUpShow({
						state: { text: parsedStatus.status, color: error },
						autoClose: false,
					}) as any,
				);
			}
			const { arrayId, projectId, ...newStatusValues } = parsedStatus;

			if (!arrayId || !projectId) return state;

			const projectStatuses = state.arrayImportStatuses[projectId] || {};

			return {
				...state,
				arrayImportStatuses: {
					...state.arrayImportStatuses,
					[projectId]: {
						...projectStatuses,
						[arrayId]: newStatusValues,
					},
				},
			};
		}
		case HubActionType.SET_APPROVAL_REQUESTS_COUNT: {
			return {
				...state,
				newApprovalsCount: Number(action.payload),
			};
		}
		case HubActionType.RECEIVE_SUPPORT_CLIENT_EVENT: {
			const { readMessageIds, unreadMessageIds, message, noMessages } = action.payload;
			const newMessages = [...state.supportMessages];
			let newUnreadSupportIds = [...state.unreadSupportMessageIds];
			const newUnreadClientIds = [...state.unreadSupportClientMessageIds];

			if (message) {
				newMessages.push(message);
				if (message.userId) newUnreadClientIds.push(message.id);
				else newUnreadSupportIds.push(message.id);
			}

			if (readMessageIds && readMessageIds.length > 0)
				readMessageIds.forEach((id) => {
					const unreadSupportIdIndex = newUnreadSupportIds.indexOf(id);
					if (unreadSupportIdIndex >= 0) newUnreadSupportIds.splice(unreadSupportIdIndex, 1);

					const unreadClientIdIndex = newUnreadClientIds.indexOf(id);
					if (unreadClientIdIndex >= 0) newUnreadClientIds.splice(unreadClientIdIndex, 1);
				});

			if (unreadMessageIds && unreadMessageIds.length > 0)
				unreadMessageIds.forEach((id) => {
					if (newUnreadSupportIds.indexOf(id) < 0) newUnreadSupportIds.push(id);
				});

			if (noMessages) newUnreadSupportIds = [];

			return {
				...state,
				supportMessages: newMessages,
				unreadSupportMessageIds: newUnreadSupportIds,
				unreadSupportClientMessageIds: newUnreadClientIds,
			};
		}
		case HubActionType.SET_NEW_NA_REASONS: {
			const data: any = JSON.parse(action.payload);
			return {
				...state,
				newNaReasons: data,
			};
		}
		case HubActionType.GLOBAL_OPTIONS_UPDATE: {
			return {
				...state,
				globalOptionsUpdate: action.payload,
			};
		}
		default:
			return state;
	}
}

function hookHubEvents(dispatch: any) {
	hub.on(HubTriggers.ReceiveClearHub, () => dispatch({ type: HubActionType.CLEAR_HUB }));

	hub.on(HubTriggers.ReceiveBroadcastMessage, (message: string) =>
		dispatch({
			type: HubActionType.ADD_MESSAGE,
			payload: {
				message,
				id: new Date().toString(),
				code: HubTriggers.ReceiveBroadcastMessage,
				creationDate: new Date().toString(),
			},
		}),
	);

	hub.on(HubTriggers.ReceiveUnreadUserMessage, (message: string) =>
		dispatch({ type: HubActionType.ADD_MESSAGE, payload: JSON.parse(message) }),
	);

	hub.on(HubTriggers.ReceiveReadMessageId, (messageId: string) =>
		dispatch({
			type: HubActionType.UPDATE_UNREAD_MESSAGE_IDS,
			payload: { messageId, isRemove: true },
		}),
	);

	hub.on(HubTriggers.ReceiveUnreadMessageIds, (messageIds: string) =>
		dispatch({ type: HubActionType.SET_UNREAD_MESSAGE_IDS, payload: JSON.parse(messageIds) }),
	);

	hub.on(HubTriggers.ReceiveRocItemStatusUpdateMessage, (message: string) =>
		dispatch({ type: HubActionType.SET_NEW_ITEM_STATUS, payload: message }),
	);

	hub.on(HubTriggers.ReceiveRocWarningsUpdateMessage, (message: string) =>
		dispatch({ type: HubActionType.SET_NEW_ITEM_WARNINGS, payload: message }),
	);

	hub.on(HubTriggers.ReceiveProjectStatusUpdateMessage, (message: string) =>
		dispatch({ type: HubActionType.SET_PROJECT_STATUS, payload: message }),
	);

	hub.on(HubTriggers.ReceiveLicenseStatus, (message: string) =>
		dispatch({ type: HubActionType.SET_LICENSE_STATUS, payload: message }),
	);

	hub.on(HubTriggers.ReceiveArrayImportStatus, (message: string) =>
		dispatch({ type: HubActionType.ARRAY_IMPORT_STATUS, payload: message }),
	);

	hub.on(HubTriggers.ReceiveSupportClientEvent, (event: string) =>
		dispatch({ type: HubActionType.RECEIVE_SUPPORT_CLIENT_EVENT, payload: JSON.parse(event) }),
	);

	hub.on(HubTriggers.ReceiveApprovalRequestsCount, (event: string) =>
		dispatch({ type: HubActionType.SET_APPROVAL_REQUESTS_COUNT, payload: JSON.parse(event) }),
	);

	hub.on(HubTriggers.ReceiveNaReasonsUpdate, (message: string) =>
		dispatch({ type: HubActionType.SET_NEW_NA_REASONS, payload: message }),
	);

	hub.on(HubTriggers.ReceiveGlobalOptionsUpdate, (message: string) =>
		dispatch({ type: HubActionType.GLOBAL_OPTIONS_UPDATE, payload: message }),
	);

	hub.onclose((e) =>
		dispatch({
			type: HubActionType.SET_STATUS,
			payload: { error: e ? JSON.stringify(e) : undefined, isConnected: false },
		}),
	);

	hub.onreconnected(() =>
		dispatch({
			type: HubActionType.SET_STATUS,
			payload: { isConnected: true },
		}),
	);

	hub.onreconnecting((e) =>
		dispatch({
			type: HubActionType.SET_STATUS,
			payload: { error: e ? JSON.stringify(e) : undefined, isConnected: false },
		}),
	);
}

export function HubContextProvider({ children }: { children: React.ReactNode }) {
	const reduxDispatch = useDispatch();
	const [state, dispatch] = React.useReducer<Reducer<HubState, HubAction>>(
		(s, a) => reducer(s, a, reduxDispatch),
		initialState,
	);

	const dispatchWrapper = React.useCallback((args) => {
		dispatch(args);
		// eslint-disable-next-line no-console -- Development only
		if (isDevelopment) console.log(args);
	}, []);
	const stateDispatch: HubContextType = React.useMemo(
		() => [state, dispatchWrapper],
		[dispatchWrapper, state],
	);

	React.useEffect(() => {
		hookHubEvents(dispatchWrapper);
	}, [dispatchWrapper]);

	return <HubContext.Provider value={stateDispatch}>{children}</HubContext.Provider>;
}

export function useHubState(): HubContextType {
	return React.useContext(HubContext);
}

export function useLicenseStatus() {
	const [hub] = useHubState();
	return hub.licenseStatus.current;
}
