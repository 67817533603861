import { css, cx } from '@emotion/css/macro';
import React from 'react';
import { error, middlegrey } from '../../ui/Core/stylesheets/colors';

function SimplifiedTextArea({ ...rest }: any) {
	const ref = React.useRef<HTMLInputElement>();

	React.useEffect(() => {
		setTimeout(() => {
			if (!ref?.current?.scrollHeight || !ref?.current?.style) return;

			// Reset to recalculate scrollHeight, this will make a field shrink quicker if text was removed
			ref.current.style.height = '0';
			ref.current.style.height = `${ref.current.scrollHeight}px`;
		}, 10);
	}, [rest.value]);

	return <textarea {...rest} ref={ref} />;
}

function SimpleConditionalInput({ isTextArea, inputRef, ...rest }: any) {
	return isTextArea ? (
		<SimplifiedTextArea {...rest} ref={inputRef} />
	) : (
		<input {...rest} ref={inputRef} />
	);
}

const simpleTextFieldClass = css`
	padding: 8px;
	border-radius: 5px;
	border: 1px solid ${middlegrey};
	font: inherit;
	width: 100%;
	resize: vertical;
	overflow: hidden;

	&:disabled {
		opacity: 0.4;
		cursor: not-allowed;
	}
`;

const errorFieldClass = css`
	border-color: ${error};
`;

type SimplifiedTextFieldProps = any;

function SimplifiedTextField({
	label,
	className,
	isTextArea,
	error,
	...rest
}: SimplifiedTextFieldProps) {
	return (
		<div>
			{label && (
				<small>
					{label}:
					<br />
				</small>
			)}
			<SimpleConditionalInput
				{...rest}
				isTextArea={isTextArea}
				className={cx(simpleTextFieldClass, error && errorFieldClass, className)}
			/>
		</div>
	);
}

export default React.memo(SimplifiedTextField);
