import { css, cx } from '@emotion/css/macro';
import React from 'react';
import { middlegrey, mainDark } from '../../ui/Core/stylesheets/colors';

const fieldSetClass = css`
	margin: 0 4px 4px 0;
	padding: 4px 8px 8px 8px;
	border: 1px solid ${middlegrey};
	border-radius: 5px;
`;

const legendClass = css`
	padding-inline-start: 4px;
	padding-inline-end: 4px;
`;

type FieldSetProps = {
	children: React.ReactNode;
	legend?: React.ReactNode;
	className?: string;
	legendColor?: string;
};

export default function FieldSet({ children, legend, className, legendColor }: FieldSetProps) {
	return (
		<fieldset className={cx(fieldSetClass, className)}>
			{legend && (
				<legend
					className={cx(
						legendClass,
						css`
							color: ${legendColor || mainDark};
						`,
					)}
				>
					{legend}
				</legend>
			)}
			{children}
		</fieldset>
	);
}
