import { DeleteForever } from '@mui/icons-material';
import Tooltip from '@material-ui/core/Tooltip';
import { useAPI } from '../../core/hooks';
import { IconButton } from '../../core/components';
import FolderTile from './FolderTile';
import { useAllowedMethods } from '../../auth/hooks';
import { darkgrey, highlight } from '../../ui/Core/stylesheets/colors';

export function AttachmentWithEdit({ setUsedPlacesList, ...rest }: any) {
	const { 'Attachments/Delete': canDelete } = useAllowedMethods();

	const { initialFetch } = useAPI({
		props: {
			query: `Attachments/Delete/${rest.projectId}/${rest.id}`,
			method: 'DELETE',
			onSuccess: (response) => {
				setUsedPlacesList(response.data);
				if (!response.data) rest.refetch();
			},
			confirmation: true,
		},
	});
	const isUsed = rest.referenceItems && rest.referenceItems.length > 0;

	return (
		<FolderTile
			additionalControls={
				<>
					{canDelete && (
						<Tooltip
							title={
								isUsed
									? 'File is used in report and cannot be removed. Click "Usage" button to list places.'
									: 'File is not used in report but might be attached in Collaborate somewhere. If you are sure that file is obsolete and not needed anymore, click this button to remove it from project completely.'
							}
						>
							<IconButton onClick={isUsed ? undefined : initialFetch} size="small">
								<DeleteForever htmlColor={isUsed ? darkgrey : highlight} />
							</IconButton>
						</Tooltip>
					)}
				</>
			}
			{...rest}
			modelId={rest.projectId}
			showAuthor
			viewableUsages
		/>
	);
}
